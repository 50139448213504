import React, { useState } from "react";
import styles from "./Project.module.scss";
import Tech from "./Tech";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineGithub } from "react-icons/ai";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import lighthouseimg from "../../images/lighthouse-logo.svg";

const Project = ({ project }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)|(webp)/" }
          relativeDirectory: { eq: "projects" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const [open, setOpen] = useState(false);

  const { lighthouse } = project;

  const allImages = data.allFile.edges;
  const image = allImages.filter((im) => im.node.name === project.image)[0].node.childImageSharp
    .fluid;

  const lighthouseImage =
    lighthouse &&
    allImages.filter((im) => im.node.name === lighthouse)[0].node.childImageSharp.fluid;

  return (
    <article className={`${styles.project} ${styles[project.class]}`}>
      <div className={styles.description}>
        <div className={styles.titleWrapper}>
          <h3>{project.name}</h3>
          <a
            href={project.link}
            target="_blank"
            className={styles.projectLink}
            rel="noreferrer noopener"
            title={`Visit ${project.name}`}
          >
            <FiExternalLink size={34}/>
          </a>
        </div>
        <div className={styles.desc}>{typeof project.desc === "string" ? <p>{project.desc}</p> : project.desc}</div>

        <div className={styles.tech}>
          {project.tech.map((tech, i) => {
            return <Tech key={i} tech={tech} />;
          })}
        </div>

        <div className={styles.icons}>
          <a className={styles.btn}
            href={project.link} 
            target="_blank" 
            rel="noreferrer noopener" 
            title={`Visit ${project.name}`}
          >
            <FiExternalLink size={24}/>
            <span>Visit</span>
          </a>
          
          {project.demo && <a className={styles.btn}
            href={project.demo} 
            target="_blank" 
            rel="noreferrer noopener" 
            title={`Try demo for ${project.name}`}
          >
            <FiExternalLink size={24}/>
            <span>Try demo</span>
          </a>}

          {project.github && <a className={styles.btn}
            href={project.github} 
            target="_blank" 
            rel="noreferrer noopener" 
            title={`Show source code for ${project.name}`}
          >
            <AiOutlineGithub size={24}/>
            <span>Source code</span>
          </a>}

          {lighthouse && (
            <button className={styles.lighthouseBtn} onClick={() => setOpen((open) => !open)}>
              <img src={lighthouseimg} alt="Lighthouse score" />
              {!open ? "Show Lighthouse score" : "Hide Lighthouse score"}
            </button>
          )}
        </div>
      </div>

      {lighthouse && open && (
        <div className={styles.lighthouseImg}>
          <Img fluid={lighthouseImage} />
        </div>
      )}
      
      <div className={styles.mainImg}>
        <Img fluid={image} alt={project.name} />
      </div>
    </article>
  );
};

export default Project;
