import React, { useRef, useEffect } from 'react';
import { Power3, TimelineLite } from "gsap";

const Overlay = () => {
    const overlay = useRef(null);
    const title = useRef(null);
    let tl = new TimelineLite({ delay: 0 });

    useEffect(() => {
        tl.to( overlay.current, { delay: 0.3, ease: Power3.easeInOut, duration: 1.3, top: "-100vh" },"Start")
          .to( title.current, { delay: 0.3, y: -60, ease: Power3.easeInOut, duration: 1.3, top: "-100vh" },"Start")
    }, []);

    return (
        <div ref={overlay} className="overlay">
            <div ref={title}>Welcome</div>
        </div>
    );
};

export default Overlay;
