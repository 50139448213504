import React, { useEffect } from "react";
import "@fontsource/oswald";
import "@fontsource/oswald/300.css";
import "@fontsource/oswald/700.css";
import "@fontsource/montserrat";
import "@fontsource/montserrat/700.css";
import "../../styles/globals.scss";
import "../../styles/variables.scss";
import "../../styles/overlay.scss";
import Scrollspy from "../../components/navigation/Scrollspy";
import Overlay from "../../components/Overlay";
import Footer from "../Footer";
import "focus-visible";
import PropTypes from "prop-types"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          url
          image
        }
      }
    }
  `);

  const site = data.site.siteMetadata;

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--height", `${vh}px`);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title={site.title} htmlAttributes={{ lang: site.lang }}>
        <meta name="description" content={site.description} />
        <meta name="image" content={site.image} />
        <meta property="og:locale" content={site.lang} />
        <meta property="og:site_name" content={site.title} />
        <meta property="og:url" content={site.url} />
        <meta property="og:title" content={site.title} />
        <meta property="og:description" content={site.description} />
        <meta property="og:image" content={site.image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
      </Helmet>
      <Overlay />
      <Scrollspy />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
