import React from "react";
import Container from "../Container";
import SectionTitle from "../SectionTitle";
import styles from "./SkillsSection.module.scss";
import skills from "../../data/skills";
import SkillsContainer from "./SkillsContainer";

const Skills = () => {
  return (
    <section id="skills" className={styles.section}>
      <Container>
        <SectionTitle main="Skills" sec="Knowledge &" />
        {skills.map(({type, skills, text}, i) => (
          <SkillsContainer key={i} title={type} skills={skills} text={text && text}/>
        ))}
      </Container>
    </section>
  );
};

export default Skills;
