import React from "react";

const SectionTitle = ({main,sec}) => {
  return (
    <div>
      <div className="subtitle">{sec}</div>
      <h2>{main}</h2>
    </div>
  );
};

export default SectionTitle;
