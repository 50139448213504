import React, { useRef, useEffect } from "react";
import styles from "./MobNav.module.scss";
import { AiFillLinkedin } from "react-icons/ai";
import { Power3, TimelineLite } from "gsap";
import ThemeSwitcher from "./ThemeSwitcher";
import useCurrentWidth from "../../hooks/useCurrentWidth";

const MobNav = () => {
  const header = useRef(null);
  let tl = new TimelineLite({ delay: 0 });

  useEffect(() => {
    tl.to(
      header.current,
      {
        delay: 2.75,
        ease: Power3.easeOut,
        duration: 1.2,
        css: { opacity: "1", visibility: "visible" },
      },
      "Start"
    );
  }, []);

  let width = useCurrentWidth();

  return (
    <header ref={header} className={styles.nav}>
      <div className={styles.contact}>
        <a
          href="https://www.linkedin.com/in/martin-hyttych"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillLinkedin />
        </a>
      </div>
      <div className={styles.menu}>{width < 700 && <ThemeSwitcher />}</div>
    </header>
  );
};

export default MobNav;
