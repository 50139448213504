import React, { useRef, useEffect } from "react";
import navlinks from "../../data/navlinks";
import { Power3, TimelineLite } from "gsap";
import styles from "./DesktopNav.module.scss";

const DesktopNav = () => {
  const nav = useRef(null);
  let tl = new TimelineLite({ delay: 0 });

  useEffect(() => {
    tl.to(
      nav.current,
      { delay: 3, ease: Power3.easeOut, duration: 1, css: { opacity: "1" } },
      "Start"
    );
  }, []);

  return (
    <nav ref={nav} className={styles.nav}>
      <ul>
        {navlinks.map((link, i) => {
          if (link.link === "home") {
            return;
          }
          return (
            <li key={i}>
              <a href={`#${link.link}`}>{link.title}</a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default DesktopNav;
