import React, { useState } from "react";
import Container from "../Container";
import Project from "./Project";
import styles from "./Projects.module.scss";
import projects from "../../data/projects";
import SectionTitle from "../SectionTitle";

const Projects = () => {
  const [currentProjects, setProjects] = useState(projects);
  const [active, setActive] = useState(0);

  const setPrrojectsHandler = (type, index) => {
    if (type === "all") {
      setProjects(projects);
    } else {
      const filtered = projects.filter((project) => project.type === type);
      setProjects(filtered);
    }
    setActive(index);
  };

  const buttons = [
    {
      title: "Show all",
      type: "all",
    },
    {
      title: "Websites",
      type: "website",
    },
    {
      title: "Web apps",
      type: "webapp",
    },
  ];

  return (
    <section id="projects" className="section">
      <Container>
        <SectionTitle sec="Some of my" main="Projects"/>
        <div className={styles.filter}>
          {buttons.map((button, index) => {
            return (
              <button
                onClick={() => setPrrojectsHandler(button.type, index)}
                key={index}
                className={`${active === index ? styles.active : ''}`}
              >
                {button.title}
              </button>
            );
          })}
        </div>
        {currentProjects.map((project) => (
          <Project key={project.id} project={project} />
        ))}
      </Container>
    </section>
  );
};

export default Projects;
