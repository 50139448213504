import Hero from '../components/hero/Hero';
import React from 'react';
import Layout from '../components/layout/Layout';
import SkillsSection from '../components/skills/SkillsSection';
import About from '../components/About/About';
import Contact from '../components/contact/Contact';
import Projects from '../components/projects/Projects';

export default function Home() {
    return (
        <Layout>
            <Hero />
            <SkillsSection/>
            <Projects/>
            <About/>
            <Contact/>
        </Layout>
    );
}
