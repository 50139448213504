import React from 'react'
import Container from '../Container'
import SectionTitle from '../SectionTitle'

const About = () => {
  return (
    <section className="section" id="about">
      <Container>
        <SectionTitle main={"About me"} sec={"Few words"}/>
        <p>I'm an experienced frontend developer with a strong background in TypeScript and React. I have over 6 years of experience with frontend development. I'm skilled in creating complex web applications with a solid understanding of web development principles and the latest frontend technologies. I'm also capable of working with backend - Node.js.</p>
        <p>I am proficient in building high-quality and user-friendly web applications from scratch but also maintaining large codebases. I have experience in agile and scrum methodologies.</p>
        <p>I'm also highly skilled in CSS, being able to code basically any design I get from a designer. I also have job experience as UI/UX designer which means I have a good grasp of UI and UX so I can provide a good design feedback if desired.</p>
        <p>I'm always trying to stay up to date with the newest technologies and continually improve my skills.</p>
      </Container>
    </section>
  )
}

export default About