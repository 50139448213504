import React, { useRef, useEffect } from "react";
import styles from "./Scrollspy.module.scss";
import Scrollspy from "react-scrollspy";
import { TweenMax, Power3 } from "gsap";
import navlinks from "../../data/navlinks";

const Nav = () => {
  const nav = useRef(null);

  useEffect(() => {
    TweenMax.to(nav.current, 0.5, {
      opacity: 1,
      ease: Power3.easeInOut,
      delay: 2.75,
    });
  }, []);

  const items = [];  
  navlinks.forEach((link) => {
    items.push(link.link);
  })

  return (
    <nav ref={nav} className={styles.nav}>
      <Scrollspy
        items={items}
        currentClassName={styles.active}
        offset={-300}
      >
        {navlinks.map((link,i) => {
          const Icon = link.icon;
          return (
            <li key={i}>
              <a href={`#${link.link}`} title={link.title}>
                <Icon />
              </a>
            </li>
          );
        })}
      </Scrollspy>
    </nav>
  );
};

export default Nav;
