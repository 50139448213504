import React from "react"

const BudgeteeOne = () => {
  return (<>
  <p>Budget application for tracking income and expenses. It supports various currencies, multiple wallets, several statistics, charts, graphs and more.</p>
  <p><strong>Includes:</strong></p>
  <p><strong>Social (Google, Facebook) and custom JWT authentication</strong> with use of refresh tokens (stored as http-only cookie) and access tokens (stored only in-memory as variable).</p>
  
  <p><strong>Custom made reusable components </strong>like Modals, Dropdowns, Select, Carousel, Portal, Toast, Inputs, Buttons and more - mostly without use of any third-party libraries.</p>

  <p><strong>Server-state management with React-query</strong>, fetching data via <strong>custom fetch wrapper</strong> with retry functionality to refresh access tokens, <strong>React-Context</strong> for client-side state management, <strong>Immer</strong> to help with immutability.</p>

  <p>Features like <strong>images upload, data filtering, charts, pagination, light/dark theme and more.</strong></p>

  <p><strong>Localization (with i18next)</strong>, SSG and SSR, <strong>styled components</strong> for styling, <strong>react-hook-form</strong> for forms.</p>

  <p><strong>Next.js API routes for REST API</strong> and <strong>MongoDB</strong> as database.</p>

  <p>If you would like to try the app without creating an account, you can try demo, altough you won't be able to add or edit any data.</p>
  </>)
}

const MTDB = () => {
  return (
  <>
  <p>IMDB-like movie and TV shows database with searching, watchlist, random movie/show generator, movie details, actors, ratings and more.</p>
  <p>Developed with <strong>Typescript and Next.js</strong> while using external REST API for fetching data. State management is done via Context. Also includes features like infinite scrolling, searching based on movie title, adding/removing movies from watchlist, option to filter by top/trending, video-trailers and more.</p>
  </>)
}

const Kovoart = () => {
  return (
  <>
  <p>Website for client's blacksmith shop featuring large list of projects with an option to filter them. The website is <strong>static generated and highly optimized</strong> for performance and good search engine results.</p>
  <p>It is developed with <strong>GatsbyJS</strong>, <strong>GraphQL</strong> and Contentful as headless CMS.</p>
  </>)
}

export default [
  // {
  //   name: "Budgetee",
  //   subtitle: "Fullstack multi-language budget application",
  //   desc: <BudgeteeOne/>,
  //   tech: ["Typescript", "React", "Next.js", "Styled components", "React-query", "JWT", "FIREBASE", "i18next", "MongoDB", "REST", ],
  //   image: "budgetee",
  //   link: "https://budgetee.martinhyttych.com",
  //   type: "webapp",
  //   class: "budgetee",
  //   id: "507dcad3-266c-4264-a460-a578147781",
  //   github: "https://github.com/MartinHyttych/Budgetee",
  //   demo: "https://budgetee.martinhyttych.com/demo"
  // },
  {
    name: "MTDb",
    subtitle: "Movie and TV show database",
    desc: <MTDB/>,
    tech: ["Typescript", "React", "Next.js", "Context", "Styled-components", "REST"],
    link: "https://mtdb.martinhyttych.cz",
    image: "mtdb",
    type: "webapp",
    class: "mtdb",
    id: "dbe466a4-704e-458f-8618-b86537fd29ac",
  },
  {
    name: "Kovoart",
    subtitle: "Website for client's blacksmith's shop",
    desc: <Kovoart/>,
    tech: ["react", "gatsby", "node.js", "graphql", "sass", "lambda", "Headless CMS"],
    link: "https://www.kovoart.cz",
    image: "kovoart",
    type: "website",
    class: "kovoart",
    id: "799aa1a5-603d-4e11-b57e-fdfd980d15aa",
    lighthouse: "kovoartlighthouse",
  },
  {
    name: "Pedicure website",
    subtitle: "Portfolio website for pedicure company.",
    desc:
      "Portfolio website for pedicure company. Created with React and Next.js. Lazy loading and custom image optimization were used to improve web performace.",
    tech: ["react", "next.js", "sass"],
    link: "https://www.pedikurakoudelkova.cz",
    image: "pedikura",
    type: "website",
    class: "pedikura",
    id: "4df72f2c-67e3-41e0-ac25-22e5412409fa",
    lighthouse: "pedikuralighthouse",
  },
  {
    name: "Agotop",
    subtitle: "Website for a plumbing and construction company.",
    desc:
      "Website for a plumbing and construction company. Technologies to build the website include: React, hooks & context, GraphQL for data fetching, Gatsby for static site generation, Contentful as a Headless CMS and Sass modules for styling.",
    tech: ["react", "gatsby", "node.js", "graphql", "sass", "Headless CMS"],
    link: "https://www.agotop.cz",
    image: "agotop",
    type: "website",
    class: "agotop",
    id: "643b1757-5fa2-4bf4-81a9-7d8efbaedafa",
    lighthouse: "agotoplighthouse",
  },
  {
    name: "Pod kovarnou",
    title: "Website for client's winery",
    desc:
      "Website for client's winery. To increase performance, website features custom lazy-loading. Website is coded with html, sass and vanilla javascript.",
    tech: ["Vanilla JS", "sass", "BEM", "html", "webpack"],
    link: "https://www.podkovarnou.cz",
    image: "podkovarnou",
    type: "website",
    class: "podkovarnou",
    id: "498a6724-6fec-4627-bb9e-454da7bdbd12",
    lighthouse: "podkovarnoulighthouse",
  }
];
