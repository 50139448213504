import React from 'react';
import styles from './Button.module.scss';

const Button = ({ children }) => {
    return <a className={styles.button} href="#skills">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {children}
        </a>;
};

export default Button;
