import React from "react";
import styles from "./Skill.module.scss";

const Skill = ({ title, icon, className }) => {
  const Icon = icon;
  return (
    <div className={`${styles.skill} ${styles[className]}`}>
      <Icon />
      <div className={styles.skillTitle}>{title}</div>
    </div>
  );
};

export default Skill;
