import styles from "./Hero.module.scss";
import Button from "../Button";
import React, { useRef, useEffect, useState } from "react";
import { TimelineLite } from "gsap";
import Typewriter from "typewriter-effect";
import MobNav from "../navigation/MobNav";
import useCurrentWidth from "../../hooks/useCurrentWidth";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AiFillLinkedin } from "react-icons/ai";
import ThemeSwitcher from "../navigation/ThemeSwitcher";
import DesktopNav from "../navigation/DesktopNav";
import useShowOnScroll from "../../hooks/useShowOnScroll"
import useDeviceDetect from "../../hooks/useDeviceDetect";

const getImage = graphql`
  query {
    file(relativePath: { eq: "martin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Hero = () => {
  const overlay = useRef(null);
  const img = useRef(null);
  const imgBox = useRef(null);
  const titleLine = useRef(null);
  const title = useRef(null);
  const switcher = useRef(null);
  const scroll = useRef(null);
  const contact = useRef(null);

  const [typeWriter, setTypeWriter] = useState(null);
  const [isSmall, setIsSmall] = useState(false);  

  let tl = new TimelineLite({ delay: 0 });
  const data = useStaticQuery(getImage);
  const image = data.file.childImageSharp.fluid;
  let width = useCurrentWidth();
  let scrolled = useShowOnScroll();
  const isMobile = useDeviceDetect()

  useEffect(() => {
    setTimeout(() => {
      setTypeWriter(true);
    }, 1500);

    if (window.innerWidth < 700) {
      tl.from( img.current, { y: -1000, duration: 1.5 }, "Start")
        .to( overlay.current, { delay: 2.75, duration: .8, css: { height: "calc(100% - 10rem)" }},"Start");        

      setIsSmall(true);
    } else {
      tl.from(img.current,{ x: -1000, duration: 1.5 },"Start")
        .to(switcher.current, { delay: 2.75, duration: .5, css: { opacity: "1", visibility: "visible"  }},"Start")
        .to(overlay.current,{ x: 1000, delay: 2.75, duration: .8, css: { width: "calc(100% - 12rem)" }}, "Start")

      setIsSmall(false);
    }

    tl.to(imgBox.current, { delay: 0, duration: 1, css: { opacity: "1", visibility: "visible" }},"Start")
      .to(titleLine.current, { delay: 0, duration: 1, css: { opacity: "1", visibility: "visible" }},"Start")
      .staggerFrom(title.current, 1, { y: 80, delay: 1 }, 0.15, "Start")
      .to(scroll.current, { delay: 2.75, duration: 1.2, css: { opacity: "1", visibility: "visible" }},"Start")  
      .to(contact.current, { delay: 2.75, duration: 1, css: { opacity: "1", visibility: "visible" }},"Start")     
  }, []);

  return (
    <section id="home" className={`${styles.hero} ${isMobile && styles.mobile}`}>
      <div ref={switcher} className={`${styles.switcher} ${isSmall && styles.switcherShow}`}>
        {width > 699 && <ThemeSwitcher/>}
      </div>      
      <div className={styles.about}>
        <div ref={imgBox} className={styles.photoBox}>
          <div ref={img} className={styles.photo}>
            <Img
              fluid={image}
              alt="Martin - full stack developer"
            />
          </div>
        </div>
        <div
          ref={overlay}
          className={`${styles.heroOverlay} ${isSmall ? styles.mobileOverlay : styles.desktopOverlay}`}
        >
          <DesktopNav/>          
          <MobNav />
        </div>
        <div className={styles.text}>
          <h1>
            <div ref={titleLine} className={styles.line}>
              <div className={styles.title} ref={title}>Hi, I'm Martin</div>
            </div>
            <div className={styles.typewriter}>
              {typeWriter && (
                <Typewriter
                  options={{
                    cursor: "",
                    delay: 60,
                  }}
                  onInit={(typewriter) => {
                    typewriter.typeString("Typescript developer").start();
                  }}
                />
              )}
            </div>
          </h1>
          <div ref={contact}  className={styles.buttons}>
            <Button>My skills</Button>
            {width > 699 && 
              <div className={styles.contact}>
                <a href="https://www.linkedin.com/in/martin-hyttych" target="_blank" rel="noopener noreferrer" className={styles.contactLink}>
                  <AiFillLinkedin />
                </a>
              </div>}
          </div>
        </div>
      </div>
      <a ref={scroll} href="#skills" className={`${styles.scroll} ${scrolled ? styles.scrolled : ""}`}>
        <div className={styles.mouse}>
          <span></span>
        </div>
        <div className={styles.arrow}>
          <span></span>
        </div>
      </a>
    </section>
  );
};

export default Hero;
