import { useState } from "react";

const useForm = (defaults) => {
  const [values, setValues] = useState(defaults);

  const [loading, setLoading] = useState(null);
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const updateValue = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onCaptchaChange = value => {
    setCaptcha(value);
  };

  const closeMsg = () => {
    setMsg("");
  };

  const submitForm = async e => {
    e.preventDefault();
    if (captcha === "") {
      setMsg("Please verify that you are not a robot");
      setErr("captcha");
      return;
    }
    setLoading(true);
    setMsg(null);
    setErr(false);

    const toSend = { values, captcha };

    // post data
    const res = await fetch(
      `${process.env.GATSBY_FUNCTIONS_URL}/sendForm`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Max-Age": "2592000",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify(toSend),
      }
    );

    // get response
    const text = JSON.parse(await res.text());

    // handle response
    if (res.status >= 400 && res.status < 600) {
      setErr(true);
      setLoading(false);
      setMsg(text.message);
    } else {
      setLoading(false);
      setErr(false);
      setMsg(text.message);
      setValues({
        name: "",
        mail: "",
        msg: "",
      });
    }
  };

  return {
    values,
    updateValue,
    submitForm,
    loading,
    msg,
    err,
    onCaptchaChange,
    closeMsg,
  };
};

export default useForm;
