import React, { useContext } from "react";
import styles from "./Form.module.scss";
import useForm from "../../hooks/useForm";
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Spinner from "../Spinner";
import { ThemeContext } from "../../context/ThemeContext";

const Form = () => {
  const { values, updateValue, submitForm, msg, loading, err, onCaptchaChange, closeMsg } = useForm(
    {
      name: "",
      mail: "",
      msg: "",
    }
  );

  const [theme] = useContext(ThemeContext);

  return (
    <form id="form" className={styles.form} onSubmit={submitForm}>
      <input
        name="name"
        placeholder="Name/Company*"
        className={styles.input}
        type="text"
        autoComplete="off"
        onChange={updateValue}
        value={values.name}
      />
      <input
        name="mail"
        placeholder="Email*"
        className={styles.input}
        type="email"
        autoComplete="off"
        onChange={updateValue}
        value={values.mail}
      />
      <textarea
        name="msg"
        placeholder="Message*"
        rows="5"
        className={`${styles.textarea} ${styles.input}`}
        type="message"
        autoComplete="off"
        onChange={updateValue}
        value={values.msg}
      />
      <div className={styles.row}>
        {theme === "light" ? (
          <ReCAPTCHA
            className={styles.captcha}
            sitekey="6Ldz0SoaAAAAAIfAMuMK_74J7rn6UuKwUpbny_PX"
            onChange={onCaptchaChange}
            theme="light"
            hl="en"
          />
        ) : (
          <ReCAPTCHA
            className={styles.captcha}
            sitekey="6Ldz0SoaAAAAAIfAMuMK_74J7rn6UuKwUpbny_PX"
            onChange={onCaptchaChange}
            theme="dark"
            hl="en"
          />
        )}
        <button type="submit" className={`${styles.submit} ${loading ? styles.loading : ""}`}>
          <div className={styles.submitText}>Send</div>
          <div className={styles.loader}>
            <Spinner />
          </div>
        </button>
      </div>
      {msg && (
        <div className={`${styles.msgWrapper} ${err ? styles.error : styles.succes}`}>
          <div>{msg}</div>
          <button title="close" className={styles.close} onClick={closeMsg}>
            <AiOutlineCloseCircle />
          </button>
        </div>
      )}
    </form>
  );
};

export default Form;
