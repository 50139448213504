import React, { useContext } from "react";
import { BsSun, BsMoon } from "react-icons/bs";
import styles from "./ThemeSwitcher.module.scss";
import { ThemeContext } from "../../context/ThemeContext";

const ThemeSwitcher = () => {
  const [theme, dispatch] = useContext(ThemeContext);

  const switchTheme = () => {
    if (theme.theme==="light") {
      dispatch({ type: "SET_DARK" })
    } else {
      dispatch({ type: "SET_LIGHT" })
    }
  };

  return (
    <button type="button" className={`${styles.switchBtn}`}>
      <input
        onChange={switchTheme}
        type="checkbox"
        className={styles.checkbox}
        id="check"
        checked={theme.theme === "light" ? true : false}
      />
      <label className={styles.switcher} htmlFor="check">
        <BsMoon />
        <BsSun />
        <div className={styles.slider} />
      </label>
    </button>
  );
};

export default ThemeSwitcher;
