import React from "react";
import styles from "./SkillsContainer.module.scss";
import Skill from "./Skill";

const SkillsContainer = ({ title, skills, text }) => {
  return (
    <section className={styles.part}>
      <h3>{title}</h3>
      {text && <p>{text}</p>}
      <div className={styles.skills}>
        {skills.map((skill, i) => (
          <Skill
            key={i}
            title={skill.title}
            icon={skill.icon}
            className={skill.class}
          />
        ))}
      </div>
    </section>
  );
};

export default SkillsContainer;
