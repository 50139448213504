import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { MdWork, MdShare } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

export default [
  { title: "Home", link: "home", icon: AiFillHome, },
  { title: "Skills", link: "skills", icon: AiFillSetting },
  { title: "Projects", link: "projects", icon: MdWork },
  { title: "About me", link: "about", icon: BsFillPersonFill },
  { title: "Contact", link: "contact", icon: MdShare },
];
