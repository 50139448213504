import {
  SiReact,
  SiRedux,
  SiNextDotJs,
  SiHtml5,
  SiCss3,
  SiNodeDotJs,
  SiMongodb,
  SiGraphql,
  SiGit,
  SiTypescript,
  SiAmazonaws,
  SiServerless,
  SiJest,
  SiApollographql
} from "react-icons/si";
import { DiSass } from "react-icons/di";
import { IoSettingsSharp } from "react-icons/io5";
import { MdExplicit } from "react-icons/md";
import Figma from '../components/Figma'
import Tailwind from '../components/Tailwind'

const skills = [
  {
    type: "Front-end",
    skills: [
      {
        title: "Typescript",
        icon: SiTypescript,
        class: "typescript"
      },
      {
        title: "React",
        icon: SiReact,
        class: "react"
      },
      {
        title: "Next.js",
        icon: SiNextDotJs,
        class: "next"
      },
      {
        title: "Tailwind",
        icon: Tailwind,
        class: "tailwind"
      },
      {
        title: "Redux",
        icon: SiRedux,
        class: "redux"
      },
      {
        title: "Sass",
        icon: DiSass,
        class: "sass"
      },
      {
        title: "CSS",
        icon: SiCss3,
        class: "css"
      },
      {
        title: "Apollo",
        icon: SiApollographql,
        class: "apollo"
      },
      {
        title: "HTML",
        icon: SiHtml5,
        class: "html"
      },
      
     
  ]
  },
  {
    type: "Back-end",
    skills: [
      {
        title: "Node.js",
        icon: SiNodeDotJs,
        class: "node"
      },
      {
        title: "Express",
        icon: MdExplicit,
        class: "express"
      },
      {
        title: "MongoDB",
        icon: SiMongodb,
        class: "mongo"
      },{
        title: "GraphQL",
        icon: SiGraphql,
        class: "graphql"
      },{
        title: "AWS",
        icon: SiAmazonaws,
        class: "aws"
      },{
        title: "Serverless framework",
        icon: SiServerless,
        class: "serverless"
      },{
        title: "REST",
        icon: IoSettingsSharp,
        class: "rest"
      }
  ]
  },
  {
    type: "Other",
    skills: [
      {
        title: "Git",
        icon: SiGit,
        class: "git"
      },{
        title: "Figma",
        icon: Figma,
        class: "figma"
      },
      ,{
        title: "Jest",
        icon: SiJest,
        class: "jest"
      },
  ]
  }
] 

export default skills