import React from 'react'
import Container from './Container'
import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div>Copyright 2021 Martin Hyttych.</div>  
      </Container>    
    </footer>
  )
}

export default Footer
