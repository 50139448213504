import React from 'react'
import Container from '../Container'
import SectionTitle from '../SectionTitle'
import styles from './Contact.module.scss'
import Form from './Form'
import { AiFillLinkedin } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";

const About = () => {
  return (
    <section className="section" id="contact">
      <Container>
        <SectionTitle main={"Contact me"} sec={"Few ways to"}/>
        <p>I prefer connecting via Linkedin, but feel free to contact me anyhow you want.</p>
        <div className={styles.contacts}>
          <a href="https://www.linkedin.com/in/martin-hyttych" target="_blank" rel="noopener noreferrer" className={styles.contact}>
            <AiFillLinkedin/>
            Martin Hyttych
          </a>
          <a href="mailto:mhyttych@gmail.com" className={styles.contact}>
            <BsFillEnvelopeFill/>
            mhyttych@gmail.com
          </a>
        </div>
        <div >
          <Form/>
        </div>
        
      </Container>
    </section>
  )
}

export default About
